import React, { useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import "./App.css";

const App = () => {
  const [demoUrl, setDemoUrl] = React.useState<string | null>(null);
  useEffect(() => {
    const url = window.location.href;

    const urlParams = new URLSearchParams(window.location.search);

    const demoUrl = urlParams.get("demoUrl");

    setDemoUrl(demoUrl);
  }, []);

  useEffect(() => {
    if (demoUrl) window.history.pushState({}, document.title, "/");
  }, [demoUrl]);

  return demoUrl ? (
    <div className="music-player-view">
      <audio
        controls
        controlsList="nodownload"
        className="music-player-view__audio"
      >
        <source src={demoUrl} type="audio/mp3" />
      </audio>
    </div>
  ) : (
    <ClipLoader size={150} color={"#123abc"} loading={true} />
  );
};

export default App;
